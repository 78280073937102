<template>
    <div class="postreqs">
        <div class="postreqs_pannel">
<!--            头部信息-->
            <div class="postreqs_pannel_header">
                <img :src="headerImg" />
                <div class="info">
                    <div class="name">{{nickName}}</div>
                    <div class="tips">
                        <img src="assets/company_tips.png"/>
                        <div class="txt1">企业</div>
                    </div>
                </div>
            </div>
<!--            内容-->
            <div class="postreqs_pannel_content">
                <div class="content_item">
                    <div class="label">服务日期</div>
                    <div class="action select_time">
                        <input type="text" placeholder="选择日期" :value="timer" readonly v-on:click="onDateTimeClick"/>
                        <img src="assets/timer_icon.png"/>
                    </div>
                </div>
                <div class="content_item">
                    <div class="label">类&nbsp;&nbsp;&nbsp;&nbsp;型</div>
                    <div class="action select_time">
                        <input type="text" placeholder="选择类型" :value="reqType" readonly v-on:click="popupVisible=true"/>
                        <img src="assets/ChevronRight.png" class="down"/>
                    </div>
                </div>
                <div class="content_item">
                    <div class="label">预&nbsp;&nbsp;&nbsp;&nbsp;算</div>
                    <div class="action select_time">
                        <input type="text" v-model="preAmount" placeholder="输入预算"/>
<!--                        <img src="assets/timer_icon.png"/>-->
                    </div>
                </div>
                <div class="content_item">
                    <div class="label label_extend">上传图片</div>
                    <div class="action unborder">
                        <div style="position: relative"  v-for="(imgFile,index) in postsImagesView"  :key="'sign'+index">
                            <div class="close">
                                <img src="assets/imgClose.png" @click="removeImgs(index)" />
                            </div>
                            <img :src="imgFile"  @click="showImg(imgFile,postsImagesView)" />
                        </div>
                        <input type="file" accept="image/jpeg,image/png" multiple="multiple" ref="fileUploadPosts" style="display: none;"/>
                        <img src="assets/fileupload.png" @click="choosePostImage" v-if="postsImagesFiles.length<3"/>
                    </div>
                </div>
                <div class="content_item content_item_extend">
                    <div class="label label_extend_width">投标说明&要求</div>
                    <div class="remark">
                        <textarea type="text" v-model="info" placeholder="写下要求..."/>
                    </div>
                </div>
                <!--            工具-->
                <div class="tools">
                    <div class="btn" @click="btnPost">确定</div>
                </div>
            </div>
        </div>

        <DatetimePicker
                ref="picker"
                type="datetime"
                v-model="pickerValue"
                year-format="{value}年"
                month-format="{value}月"
                date-format="{value}日"
                hourFormat="{value}时"
                minuteFormat="{value}分"
                :startDate="startDate"
                @confirm="handleConfirm"
        >
        </DatetimePicker>

        <Popup class="mint-popup dtype_mint-popup"
                v-model="popupVisible"
                position="bottom">
            <Picker
                :slots="dataType"
                @change="onDataChange"
                :visible-item-count="3"
                :show-toolbar="true"
                ref="typepicker"
                value-key="cName"
            ></Picker>
        </Popup>


    </div>
</template>

<script>
    import { DatetimePicker,Popup,Picker,Toast,Indicator  } from 'mint-ui';
    import keys from "../../xyg/utils/keys";
    export default {
        name: "PostReqs",
        data(){
            return{
                pickerValue:false,
                startDate:new Date(),
                timer:'',
                nickName:'',
                headerImg:'',
                reqType:'',
                preAmount:0,
                popupVisible:false,
                postsImagesFiles:[],
                postsImagesView:[],
                info:'',
                dataType:[
                    {
                       flex: 1,
                       values: ["设备维修","设备保养","设备改造","项目外包","新进设备"],
                       className: 'slot1',
                       textAlign: 'center'
                     }
                ]
            }
        },
        components:{
            DatetimePicker,
            Popup,
            Picker
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                let that=this;
                if(this.$refs.fileUploadPosts!=null && this.$refs.fileUploadPosts!=undefined){
                    this.$refs.fileUploadPosts.addEventListener('change',function (event) {
                        that.handleFileUploadImages(event.target);
                    })
                }
                this.headerImg=localStorage.getItem(keys.headUrl);
                this.nickName=localStorage.getItem(keys.nickName);
            },
            onDateTimeClick(){
                this.$refs.picker.open();
            },
            handleConfirm(v){
                console.log(this.$moment(v).format('YYYY-MM-DD HH:mm'))
                this.timer=this.$moment(v).format('YYYY-MM-DD HH:mm');
            },
            onDataChange(picker, values){
                console.log(picker)
                console.log(values)
                this.reqType=values[0];
            },
            choosePostImage(){
                this.$refs.fileUploadPosts.click();
            },
            handleFileUploadImages(target){
                let files=target.files;
                if(files==null) return;
                if(files==undefined) return;
                if(files.length==0) return;
                if(files.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                if(this.postsImagesFiles.length>3){
                    Toast({
                        message: '最多只能选择3张图片,不能再添加图片了',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                let that=this;
                for(var i=0 ;i<files.length;i++){
                    this.postsImagesFiles.push(files[i]);

                    var reader= new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload=function (e) {
                        that.postsImagesView.push(e.target.result);
                    }
                }
            },
            getReqTypeId(){
                if(this.reqType=='设备维修')return 1;
                if(this.reqType=='设备保养')return 2;
                if(this.reqType=='设备改造')return 3;
                if(this.reqType=='新进设备')return 4;
                if(this.reqType=='项目外包')return 5;
                return 0;
            },
            btnPost(){
                if(this.timer==undefined || this.timer==null || this.timer.trim().length==0){
                    Toast({
                        message: '请选择服务时间',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                if (this.postsImagesFiles.length>3){
                    Toast({
                        message: '最多只能选择3张图片',
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }

                Indicator.open('加载中...');

                let openId=localStorage.getItem(keys.openId);
                var formData=new FormData();
                formData.append('time',this.timer);
                formData.append('openId',openId);
                formData.append('category',this.getReqTypeId());
                formData.append('perAmount',this.preAmount);
                for(var i=0 ;i<this.postsImagesFiles.length;i++){
                    formData.append('files[]',this.postsImagesFiles[i]);
                }
                formData.append('info',this.info);

                this.$upload("/api/product/release",formData)
                    .then(rsp=>{

                        Indicator.close();
                        if (rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });
                            this.closeDraw();
                        }else{
                            Toast({
                                message: rsp.message,
                                position: 'bottom',
                                duration: 1500
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                        Indicator.close();
                    })

            },
            showImg(path,imgs){
                if(imgs.length>0) {
                    // console.log('pictures',pics)
                    this.$wx.previewImage({
                        current: path,
                        urls: imgs
                    });
                }
            },
            removeImgs(idx){
                this.postsImagesFiles.splice(idx,1);
                this.postsImagesView.splice(idx,1);
            }
        }
    }
</script>

<style scoped>
    .postreqs{
        width: 750px;
        height: 100%;
        letter-spacing: 2px;
        background-color: #E7EAF1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .postreqs >>> .picker-item{
        font-size: 26px !important;
    }

    .postreqs >>> .picker-selected{
        color: #26a2ff !important;
    }

    .postreqs .postreqs_pannel{
        margin-left: 20px;
        margin-top: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
    }

    .postreqs .postreqs_pannel .postreqs_pannel_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 20px;
    }

    .postreqs_pannel_header img{
        width:100px;
        height: 100px;
        border-radius: 100px;
    }

    .postreqs_pannel_header .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 24px;
    }

    .info .name{
        font-size: 26px;
    }

    .select_time .down{
        width: 20px !important;
        height: 12px !important;
    }

    .info .tips{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        margin-left: 4px;
    }

    .info .tips img{
        width: 14px !important;
        height: 18px !important;
        border-radius: unset !important;
    }

    .info .tips .txt1{
        font-size: 22px;
        color:#A79F9F;
        margin-left: 8px;
    }

    .postreqs_pannel .postreqs_pannel_content{
        border-top: 2px #F1F1F1 solid;
        padding-bottom: 30px;
    }

    .postreqs_pannel_content .content_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 16px;
        color:#A79F9F;
    }

    .content_item .label{
        font-size: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 120px;
    }

    .label_extend{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .label_extend_width{
        width: 200px !important;
    }

    .content_item .action{
        margin-left: 16px !important;
        flex:1 auto;
        border: 2px #E5E8E9 solid;
    }
    .select_time{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
    }

    .unborder{
        border:unset !important;
        flex-direction: row !important;
        align-items: center;
        /*justify-content: center;*/
        display: flex;
    }

    .unborder img{
        margin-right: unset !important;
        /*flex: 1;*/
    }

    .unborder .close{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 40px;
        margin-top: unset !important;
    }

    .unborder .close img{
        width: 40px !important;
        height: 40px !important;
    }

    .select_time input[type=text]{
        border: none;
        outline: medium;
        padding:0px;
        height: 60px;
        font-size: 28px;
        margin-left: 16px;
        flex:1;
    }

    .select_time img{
        width: 32px !important;
        height: 32px !important;
    }

    .action img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .content_item_extend{
        /*display: flex;*/
        align-items: stretch !important;
        flex-direction: column !important;
        justify-content: center !important;
    }
    .remark{
        margin-top: 16px;
    }

    .remark textarea{
        height: 200px !important;
        width: 100%;
        border-color: #A79F9F;
        /*border: none;*/
        border-radius: 8px;
        outline: medium;
        padding:2px;
        letter-spacing: 2px;
        font-size: 26px;
    }

    .tools{
        display: flex;
        flex-direction: row-reverse;
    }

    .btn{
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 30px;
        background-color: #226DF8;
        color: white;
        height: 80px;
        border-radius: 8px;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .picker-item{
        height: 36px !important;
        line-height: 36px !important;
    }

    .dtype_mint-popup{
        width: 750px;
    }
</style>
